import React, { useEffect, useState } from "react";
import download from 'downloadjs';

import { AudioManager } from "./AudioManager.tsx";
import Transcript from "./Transcript.tsx";
import { useTranscriber } from "../../hooks/useTranscriber.ts";

import FileControl from './FileControl/FileControl';
import { useStore } from '../../hook-store/store';
import { LANGUAGES } from "./AudioManager.tsx";
// import { marks } from '../../utils/marks';
import './TranscribeAudio.css';


function TranscribeAudio() {
    const transcriber = useTranscriber();

    // console.log(transcriber);

    const [store, dispatch] = useStore();
    // console.log('store-App.tsx', store);
    const { mlIsBusy } = store.uiStore;
    const { 
        mlSpeechCurrentAudioData,
        mlSpeechText,
    } = store.mlSpeechStore;
    
    useEffect(() => {
        if (transcriber.output?.text && !transcriber.isBusy) {
            dispatch('SET_MLSPEECHTEXT', transcriber.output.text);
        }
    },[transcriber.output]);

    useEffect(() => {
        dispatch('SET_MLISBUSY', transcriber.isBusy);
    },[transcriber.isBusy]);

    useEffect(() => {
        const lsTranscribeSelectLng = localStorage.getItem('transcribeSelectLng');

        if (lsTranscribeSelectLng && lsTranscribeSelectLng !== 'en') {
            transcriber.setMultilingual(true);
            transcriber.setLanguage(lsTranscribeSelectLng);
        }

        if (!lsTranscribeSelectLng) {
            const detectedLanguage = navigator.languages[0];

            const isDetectLng = LANGUAGES[detectedLanguage?.split('-')[0]];
            // console.log('isDetectedLanguage', isDetectLng);
            if (isDetectLng && isDetectLng !== 'en') {
                transcriber.setMultilingual(true);
                transcriber.setLanguage(detectedLanguage.split('-')[0]);
            }
        }

    },[]);


    const getAudioData = (audioData: any) => {
        // console.log(audioData)
        dispatch('SET_MLSPEECHCURRENTAUDIODATA', audioData);
    };

    const textDownloadHandler = (text: string) => {
        download(
            text, 
            `audio-transcript-${Date.now()}.txt`, 
            "text/plain"
        );
    };


    let bannerElement;

    if (window.innerWidth <= 450) {
      bannerElement = (
        <iframe id="kura-text-audio-tools.spaceeight.net-1725583665876"
            style={{width:"310px", height:"70px", border:"none"}}  
            src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583665876"
        />
      );
    }
  
    if (window.innerWidth > 450 && window.innerWidth <= 768) {
      bannerElement = (
        <iframe id="kura-text-audio-tools.spaceeight.net-1725583679744"
        style={{width:"460px", height:"95px", border:"none"}}  
        src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583679744"
        />
      )
    }
  
    if (window.innerWidth > 768) {
      bannerElement = (
        <iframe id="kura-text-audio-tools.spaceeight.net-1725583699146"
            style={{width:"610px", height:"135px", border:"none"}}  
            src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583699146"
        />
      );
    }

    return (
        <div>
            <div 
                style={{
                    display: 'flex',
                    justifyContent: "center",
                    paddingTop: "1rem",
                    paddingBottom: "1rem"
                }}
            >
                {bannerElement}
            </div>

            <div 
                // className='flex justify-center items-center min-h-screen'
                className='flex justify-center items-center'
            >
                <div className='container flex flex-col justify-center items-center'>
   
                    <div className="transcribeAudioTitle">
                        Audio Transcriber
                    </div>

                    <AudioManager 
                        transcriber={transcriber} 
                        getAudioData={getAudioData}
                    />
                    <Transcript transcribedData={transcriber.output} />
                </div>

            </div>
            
            <FileControl /> 
        </div>
    );
}

export default TranscribeAudio;
