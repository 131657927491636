import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_MLSPEECHTEXT: (state, payload) => {
      return { 
        // ...state,
        mlSpeechStore : {
          ...state.mlSpeechStore,
          mlSpeechText: payload, 
        }
      };
    },
    SET_MLSPEECHCURRENTAUDIODATA: (state, payload) => {
      return { 
        // ...state,
        mlSpeechStore : {
          ...state.mlSpeechStore,
          mlSpeechCurrentAudioData: payload, 
        }
      };
    },
    SET_MLSPEECHINPUTFILENAME: (state, payload) => {
      return { 
        // ...state,
        mlSpeechStore : {
          ...state.mlSpeechStore,
          mlSpeechInputFileName: payload, 
        }
      };
    },

  };

  initStore(actions, {
    mlSpeechStore: {
      mlSpeechText: null,  
      mlSpeechCurrentAudioData: null,
      mlSpeechInputFileName: '',
    }
  });
};

export default configureStore;
