import { useState, useEffect, Fragment } from 'react'

import Loader from '../Loader/Loader';
import VoiceFileControl from './FileControl/VoiceFileControl';
import { useStore } from '../../hook-store/store';

import './VoiceGenerator2.css';

function VoiceGenerator2() {

  const [store, dispatch] = useStore();

  // const [textInput, setTextInput] = useState('');
  // const [speakerId, setSpeakerId] = useState();
  const [speed, setSpeed] = useState("");
  const [isModelLoading, setIsModelLoading] = useState(true);
  // const [outputEls, setOutputEls] = useState();

  const [audioBlobUrls, setAudioBlobUrls] = useState([]);
  const [blobUrlLength, setBlobUrlLength] =useState(0);
  const [outputAudioEls, setOutputAudioEls] = useState([]);
  // const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    var body = document.getElementsByTagName('body')[0];
    const appTtsEl = document.getElementById('app-tts-js');
    const onnxTtsJsEl = document.getElementById('sherpa-onnx-tts-js');
    const ttsWasmMainEl = document.getElementById('onnx-wasm-main-tts-js');

    // <script src="app-tts.js"></script>
    // <script src="sherpa-onnx-tts.js"></script>
    // <script src="sherpa-onnx-wasm-main-tts.js"></script>
    if (!appTtsEl) {
      var js = document.createElement("script");
      js.id = "app-tts-js";
      js.src = "app-tts.js";
      body.appendChild(js);
    }

    if (!onnxTtsJsEl) {
      var js2 = document.createElement("script");
      js2.id =  "sherpa-onnx-tts-js";
      js2.src = "sherpa-onnx-tts.js";
      body.appendChild(js2);
    }
    
    if (!ttsWasmMainEl) {
      var js3 = document.createElement("script");
      js3.id = "onnx-wasm-main-tts-js"
      js3.src = "sherpa-onnx-wasm-main-tts.js";
      body.appendChild(js3);
    }
  },[])

  useEffect(() => {
    //// update model loading state
    const loadIntarval = setInterval(() => {
      getHintContent();
    }, 1000)

    const getHintContent = () => {
      const hintContent = document.getElementById('tts-hint').innerHTML
      
      if (!hintContent.startsWith('Loading')) {
        setIsModelLoading(false);
        clearInterval(loadIntarval);
      }
      return hintContent;
    }

    return () => {
      clearInterval(loadIntarval);
    }
  },[]);


  useEffect(() => {
    const loadIntarval = setInterval(() => {
      // getHintContent();
      setAudioBlobUrlsHandler();
    }, 3000)

    const setAudioBlobUrlsHandler = () => {
      const outputAudioElements = document.getElementsByClassName('tts-output-audio-element');
      
      const srcList = [];
      
      for (const audioEl of outputAudioElements) {
        srcList.push(audioEl.currentSrc);
      }
      
      // console.log(outputAudioElements);
      setOutputAudioEls(outputAudioElements);
      setAudioBlobUrls(srcList);
      dispatch('SET_OUTPUTBLOBURL', srcList[srcList.length-1]);

      // const generationStatus = document.getElementById('tts-generation-status');
      // if (generationStatus && generationStatus.className === 'generating') {
      //   setIsGenerating(true);
      // } else {
      //   setIsGenerating(false);
      // }
    }

    return () => {
      clearInterval(loadIntarval);
    }
  },[]);

  useEffect(() => {
    // console.log(audioBlobUrls.length);
    if (blobUrlLength !== 0 && audioBlobUrls.length > blobUrlLength) {
      setBlobUrlLength(audioBlobUrls.length);

      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'audio generated',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 5);
    }

    if (blobUrlLength === 0 && audioBlobUrls.length > blobUrlLength) {
      setBlobUrlLength(audioBlobUrls.length);
    }
  },[audioBlobUrls]);


  const textInputHandler = (event) => {
    // setTextInput(event.target.value);
    const textArea = document.getElementById('tts-text');
    textArea.value = event.target.value;
  }

  const generateAudioHandler = () => {
    const generateBtn = document.getElementById('tts-generateBtn');
    generateBtn.click();
  };

  const changeSpeakerIdHandler = (event) => {
    const speakerIdInput = document.getElementById('tts-speakerId');
    speakerIdInput.value = event.target.value;
  }

  const changeSpeedHandler = (event) => {
    const speedInput = document.getElementById('tts-speed');
    const speedValue = document.getElementById('tts-speedValue');

    speedInput.value = event.target.value;
    speedValue.innerHTML = event.target.value;
    setSpeed(event.target.value);
  }


  let speakerNum = 0;
  // let isText = false;

  const speakerIdLabel = document.getElementById('tts-speakerIdLabel');

  if (speakerIdLabel && Number(speakerIdLabel.innerHTML) > 0) {
    speakerNum = Number(speakerIdLabel.innerHTML); 
  }

  let textInputValue ='';
  let speakerIdInputValue = '';
  let speedInputValue = '';

  const textArea = document.getElementById('tts-text');
  const speakerIdInput = document.getElementById('tts-speakerId');
  const speedInput = document.getElementById('tts-speed');

  if (textArea) {
    textInputValue = textArea.value;
  }

  if (speakerIdInput) {
    speakerIdInputValue = speakerIdInput.value;
  }

  if (speedInput) {
    speedInputValue = speedInput.value;
  }



  let outputBody;

  const displayList = [];

  if (audioBlobUrls.length > 0) {
    const ttsClipLabels = document.getElementsByClassName('tts-clip-label');

    for (let i=0; i<audioBlobUrls.length; i++) {
      displayList.push({
        blobUrl: audioBlobUrls[i],
        text: ttsClipLabels[i].innerHTML,
        time: Number(outputAudioEls[i].id?.split('-')[1]),
      });
    }

    outputBody = (
      <ul>
        {[displayList[audioBlobUrls.length-1]].map((ele, index) => {
          // console.log(ele)
          return (
            <li key={ele.blobUrl}
              className='voiceGenerator2OutputCard'
            >
              <audio className='voiceGenerator2Audio'
                src={ele.blobUrl}
                controls 
              />
              <div>
                {ele.text.length > 100 && (
                  <span>{ele.text.substring(0, 100)}....</span>
                )}
                {ele.text.length <= 100 && (
                  <span>{ele.text}</span>
                )}
              </div>
              {ele.time > 0 && (
                <div>
                  {new Date(ele.time).toLocaleString()}
                </div>
              )}
            </li>
        )
      })}
      </ul>
    )
  }

  let bannerElement;

  if (window.innerWidth <= 450) {
    bannerElement = (
      <iframe id="kura-text-audio-tools.spaceeight.net-1725583665876"
          style={{width:"310px", height:"70px", border:"none"}}  
          src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583665876"
      />
    );
  }

  if (window.innerWidth > 450 && window.innerWidth <= 768) {
    bannerElement = (
      <iframe id="kura-text-audio-tools.spaceeight.net-1725583679744"
      style={{width:"460px", height:"95px", border:"none"}}  
      src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583679744"
      />
    )
  }

  if (window.innerWidth > 768) {
    bannerElement = (
      <iframe id="kura-text-audio-tools.spaceeight.net-1725583699146"
          style={{width:"610px", height:"135px", border:"none"}}  
          src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583699146"
      />
    );
  }

  return (
    <Fragment>
      <div className='voiceGenerator2'>
        <div 
          style={{
                  display: 'flex',
                  justifyContent: "center",
                  paddingTop: "1rem",
                  paddingBottom: "2rem"
              }}
          >
            {bannerElement}
        </div>

        <div className='voiceGenerator2Section'>
          <div className='voiceGenerator2Title'>
            Voice Generator
          </div>
        </div>
        
        {isModelLoading && (
          <div className='voiceGenerator2Section'>
            model loading... 
            <br/>
            <Loader />
          </div>
        )}

        <div className='voiceGenerator2Section'>
          This page supports English only
        </div>

        <div className='voiceGenerator2Section'>
          <div className='voiceGenerator2SettingRow'>
            <div>
              <label htmlFor="speakerId">Speaker ID: </label>
              <input 
                className='voiceGenerator2SpeakerInput'
                type="text" name="speakerId" 
                // value="0" 
                placeholder={`0 - ${speakerNum}`}
                onChange={changeSpeakerIdHandler}
                defaultValue={speakerIdInputValue}
              />
            </div>
            <div className='voiceGenerator2SettingSpeed'>
              <label htmlFor="speed">Speed: </label>
              <input className='voiceGenerator2SettingSpeedInput'
                type="range" name="speed" 
                min="0.4" max="3.5" step="0.1" 
                value={speed ? speed : speedInputValue} 
                onChange={changeSpeedHandler}
                defaultValue={speakerIdInputValue}
              />
              {speed && (
                <span>{speed}</span>
              )}
              {!speed && (
                <span>{speedInputValue}</span>
              )}
            </div>
          </div>
        </div>

        <div className='voiceGenerator2Section'>
          <div>
            <label>
              text input
            </label>
          </div>
          <textarea 
            className='voiceGenerator2TextInput'
            rows="3" 
            placeholder="Please enter your text here and click the Generate button"
            onChange={textInputHandler}
            defaultValue={textInputValue}
          ></textarea>
        </div>
        
        <div className='voiceGenerator2Section'>
          <button className='btnBase'
            style={{ padding: "0.75rem", fontSize: "1.25rem", fontWeight: "bold"}}
            disabled={isModelLoading}
            onClick={generateAudioHandler}
          >
            Generate audio
          </button>
        </div>

        {/* {isGenerating && (
          <Loader />
        )} */}

        <span id='tts-audio-output'></span>
        <section></section>

        <div className='voiceGenerator2Section'>
          {outputBody}
        </div>

        {audioBlobUrls.length > 0 && (
          <VoiceFileControl 
            textForOutput={displayList[displayList.length - 1].text}
          />
        )}
      </div>
    </Fragment>
  )
}

export default VoiceGenerator2;
