import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useTranscriber } from "../../../hooks/useTranscriber.ts";
import download from 'downloadjs';

import CreateInputFile from "./CreateInputFile.jsx";
import TranscriberFileUpload from "./TranscriberFileUpload.jsx";

import { useStore } from '../../../hook-store/store';
import { textToFile } from '../../../utils/text-util.js';

import { marks } from '../../../utils/marks';
import './FileControl.css';

function FileControl() {

    const [t] = useTranslation("translation");

    const [store, dispatch] = useStore();
    // console.log('store-App.tsx', store);
    const { mlIsBusy } = store.uiStore;
    const { 
        mlSpeechCurrentAudioData,
        mlSpeechText,
    } = store.mlSpeechStore;

    const [transcribeTextFile, setTranscribeTextFile] = useState();
    const [showUploadModal, setShowUploadModal] = useState(false);
    

    const textDownloadHandler = (text) => {
        download(
            text, 
            `audio-transcript-${Date.now()}.txt`, 
            "text/plain"
        );
    };

    const createTextFileHandler = (text) => {
        const textFile = textToFile(text);
        setTranscribeTextFile(textFile);
    };

    return (
        <div className="transcribeFileControl">

            {!mlIsBusy && mlSpeechText && (
                <div className="transcribeFileControlButtons">
                    {/* <button
                        onClick={() => {
                            navigator.clipboard.writeText(mlSpeechText);
                        }}
                    >
                        text-copy-to-clipboard {marks.copyMark}
                    </button> */}
                    <button className="btnBase"
                        onClick={() => {
                            textDownloadHandler(mlSpeechText);
                        }}
                    >
                        {marks.fileTextOMark} 
                        {' '}
                        {t('audioTranscriber15', 'Download text')} 
                        {' '}{marks.downloadMark}
                    </button>
                    <button className="btnBase"
                        onClick={() => { 
                            createTextFileHandler(mlSpeechText);
                            setShowUploadModal(true);
                        }}
                    >
                        {marks.fileTextOMark} 
                        {' '}
                        {t('audioTranscriber16', 'Upload text file')}
                        {' '} 
                        {marks.uploadMark}
                    </button>

                    <CreateInputFile />
                </div>
            )}

            {showUploadModal && (
                <TranscriberFileUpload 
                    uploadFiles={[transcribeTextFile]}
                    setShowUploadModal={setShowUploadModal}
                />
            )}

            {/* {!mlIsBusy && mlSpeechCurrentAudioData && (
                <div>
                    <CreateAudioFile />
                </div>
            )} */}
                
        </div>
    );
}

export default FileControl;
