import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import LanguageList from '../../translation/LanguageList';
import { useStore } from '../../hook-store/store';

import classes from './Footer.module.css';


function Footer(props) {
  // console.log('Layout.js-props', props);

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;
  // console.log('store- Layout.js', store);


  return (
    <Fragment>

      <div className={classes.footer}>

        {/* <div className={classes.footerLinks}>
          <span className={classes.footerLink}>
            <Language 
              place='footer'
            />
          </span>
        </div> */}

        <div className={classes.footerLinks}>
          <span className={classes.footerLink}>
            <Link to="/about">{t('footer.03', 'About page')}</Link>
          </span>
          <span className={classes.footerLink}>
            <Link to="/privacypolicy">{t('footer.01', 'privacy policy')}</Link>
          </span>
          <span className={classes.footerLink}>
            <Link to="/termsofuse">{t('footer.02', 'terms of use')}</Link>
          </span>
          {/* {isAuth && (
            <span className={classes.footerLink}>
              <Link to="/contact">contact</Link>
            </span>
          )} */}
          {/* <span className={classes.footerLink}>
            Kura Storage by SpaceEight
          </span> */}
        </div>
        
        <div className={classes.footerLinks}>
          <span className={classes.footerLink}>
            Kura Text Audio Tools for Kura Storage by SpaceEight
          </span>
        </div>
      </div>

    </Fragment>
  );
}

export default Footer;
