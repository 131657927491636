// import _ from 'lodash';
// import { forTranslation } from './forTanslation';

// import { languages } from './config';
// const { languages } = require('./config');
import { languages } from './config';

import languageList from './language-data/ctoj.json';

// console.log(languages)


// const trDataList = [];

// for (const [index, value] of languages.entries()) {
// 	const data = await import('./locales/' + languages[index] + '/common.json')
// 	trDataList.push(data);
// }

// console.log(trDataList);


// export const resources = Object.assign(
// 	{},
// 	...Object.keys(languages).map((index) => {
// 		return {
// 			[languages[index]]: {
// 				// translations: require('../locales/' + languages[index] + '/translation.json'),
// 				// translation: require('./locales/' + languages[index] + '/common.json'),
// 				translation: trDataList[index]
// 				// translations: require('./locales/' + 'af' + '/common.json'),
// 			},
// 		};
// 	}),
// );

// console.log('language resources', resources);


export const resources = Object.assign(
	{},
	...Object.keys(languages).map((index) => {

		const matchObject = languageList.find(element => {
			return element.CODE === languages[index];
		});

		if (matchObject) {
			return {
				[languages[index]]: {
					translation: matchObject
				},
			};
		}
	}),
);

console.log('language resources', resources);





// const createTranslationResources = async (languages) => {

// 	const trDataList = [];
// 	// for (let i=0; i<languages.length; i++) {
// 	// 	const data = await import('./locales/' + languages[i] + '/common.json')
// 	// 	trDataList.push(data);
// 	// }

// 	for (const [index, value] of languages.entries()) {
// 		const data = await import('./locales/' + languages[index] + '/common.json')
// 		trDataList.push(data);
// 	}

// 	console.log(trDataList);


// 	const resources = Object.assign(
// 		{},
// 		...Object.keys(languages).map((index) => {
// 			return {
// 				[languages[index]]: {
// 					// translations: require('../locales/' + languages[index] + '/translation.json'),
// 					// translation: require('./locales/' + languages[index] + '/common.json'),
// 					translation: trDataList[index]
// 					// translations: require('./locales/' + 'af' + '/common.json'),
// 				},
// 			};
// 		}),
// 	);

// 	return resources;
// }

// export let resources;

// resources = await createTranslationResources(languages);

// console.log(resources);