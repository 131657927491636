import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import VideoToAudio from 'video-to-audio';

import AboutAuth from "../../UI/AboutAuth";
import Backdrop from "../../Backdrop/Backdrop";
import Modal from "../../Modal/Modal";
import Spinner from "../../Spinner/Spinner";
import FilesUploadAction from "../../FileUploadAction/FilesUploadAction";

import { useStore } from '../../../hook-store/store';

import { marks } from "../../../utils/marks";
import './TranscriberFileUpload.css';

function CreateInputFile(props) {
    const { 
      audioData,
      transcriber,
     } = props;

    const [t] = useTranslation("translation");

    // console.log('audioData', audioData);
    const [store, dispatch] = useStore();
    // console.log('store-App.tsx', store);
    const { isAuth } = store.uiStore;
    const { mlSpeechCurrentAudioData } = store.mlSpeechStore;
    
    const [currentAudioFile, setCurrentAudioFile] = useState('');
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    
     useEffect(() => {
      if (audioData && mlSpeechCurrentAudioData && audioData?.url !== mlSpeechCurrentAudioData.url) {
        // setCurrentAudioData(audioData);
        dispatch('SET_MLSPEECHCURRENTAUDIODATA', audioData);
      }
     },[audioData]);



    function downloadAudio(convertedAudioDataObj) {
        let a = document.createElement("a");
        a.href = convertedAudioDataObj.data;
        a.download = convertedAudioDataObj.name + "-download." + convertedAudioDataObj.format;
        a.click();
    }

    async function createFileFromAudio(audioData) {
      try {
        setIsLoading(true);

        const result = await fetch(audioData.url);
        const resData = await result.blob();

        const file = new File(
          [resData], 
          `audio-transcribe-input-${Date.now()}.${audioData.mimeType.split('/')[1]}`, 

          { type: audioData.mimeType }
        );

        setCurrentAudioFile(file);
        setIsLoading(false);
        // dispatch('SET_SPEECHAUDIOFILE', file);

        console.log('audiodata file', file, audioData);
      } catch(err) {
        console.log(err);
        setIsLoading(false);
      }
  }


  return (
      <Fragment>
        <button className="btnBase"
          onClick={() => {
            createFileFromAudio(mlSpeechCurrentAudioData);
            setShowUploadModal(true);
          }}
        >
          {marks.fileAudioOMark} 
          {' '}
          {t('audioTranscriber17', 'Upload audio file')}
          {' '}
          {marks.uploadMark}
        </button>

        {showUploadModal && (
          <div>
            <Backdrop 
              zIndex={'95'}
              onCancel={() => { 
                setShowUploadModal(false);
              }}
            />
            <Modal
              onClose={() => {
                setShowUploadModal(false);
              }}
            >
              {isLoading && (
                <Spinner />
              )}

              <div className='transcriberUploadSection'>
                <div className='transcriberUploadTitle'>
                  Upload file to Kura Storage
                </div>
              </div>
              
              {currentAudioFile && currentAudioFile.type?.split('/')[0] === 'audio' && (
                <div className='transcriberUploadSection'>
                  <audio
                    style={{maxWidth:"100%"}}
                    controls
                    src={URL.createObjectURL(currentAudioFile)}
                  />
                </div>
              )}
              {currentAudioFile && currentAudioFile.type?.split('/')[0] === 'video' && (
                <div className='transcriberUploadSection'>
                  <video
                    style={{maxHeight: "400px", maxWidth:"100%"}}
                    controls
                    src={URL.createObjectURL(currentAudioFile)}
                  />
                </div>
              )}
              <br/>
              
              {currentAudioFile && isAuth && (
                <FilesUploadAction 
                  uploadFiles={[currentAudioFile]}
                  uploadApp={'audioTranscriber'}
                />
              )}
              {currentAudioFile && !isAuth && (
                <AboutAuth />
              )}
            </Modal>
          </div>
        )}
      </Fragment>
    );
}

export default CreateInputFile;
