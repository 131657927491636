import { useState, useEffect, Fragment } from 'react';
// import _, { isLength } from 'lodash';
// import axios from 'axios';
import AboutAuth from '../../UI/AboutAuth';
import Backdrop from '../../Backdrop/Backdrop';
import Modal from '../../Modal/Modal';
import FilesUploadAction from '../../FileUploadAction/FilesUploadAction';

import { useStore } from '../../../hook-store/store';

import { marks } from '../../../utils/marks';
// import './VoiceFileUpload.css';

function AnswerFinderFileUpload(props) {

  const { 
    combinedFile,
    setShowUploadModal,
  } = props;
  // console.log('VoiceFileUpload.jsx', props)
  
  const [store, dispatch] = useStore();
  // console.log('store-App.tsx', store);
  const { isAuth } = store.uiStore;
  
  


  // useEffect(() => {
  //   if (gLoading) {
  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //   }
  // },[gLoading]);

  // let displayText = mlSpeechText;

  // if (mlSpeechText.length > 300) {
  //   displayText = mlSpeechText.slice(0, 300) + '....';
  // }

  // console.log(uploadFiles)

 
  let answerFinderFileUploadBody;

  answerFinderFileUploadBody = (
    <div>
      <Backdrop 
        zIndex={'95'}
        onCancel={() => { 
          setShowUploadModal(false);
        }}
      />
      <Modal
        onClose={() => {
          setShowUploadModal(false);
        }}
      >
        <div >
          <div className='voiceFileUploadSection'>
            <div className='voiceFileUploadTitle'>
              Upload files to Kura Storage
            </div>
          </div>

          {/* {combinedFile && (
            <div className='voiceFileUploadSection'>
              {combinedFile.name}
            </div>
          )} */}

          
          {isAuth && (
            <div className='voiceFileUploadSection'>
              <FilesUploadAction 
                uploadFiles={[combinedFile]}
                uploadApp={'answerFinder'}
              />
            </div>
          )}
          {!isAuth && (
            <div className='voiceFileUploadSection'>
              <AboutAuth />
            </div>
          )}
        </div>
      </Modal>
    </div>
  )


  
  return (
    <Fragment>
      {answerFinderFileUploadBody}
    </Fragment>
  );
}

export default AnswerFinderFileUpload;
