import axios from 'axios';


// export const s3fileUpload = (url, token, path, selectedFiles, bucketName) => {
//   return new Promise((resolve, reject) => {
//     const data = new FormData(); 

//     // console.log(selectedFiles);
//     for (const file of selectedFiles) {
//       data.append('files', file);
//     }
//     // data.append('files', selectedFiles);

//     data.append('storePath', path);
//     data.append('bucket', bucketName);
    
//     if (localStorage.getItem('customerId')) {
//       data.append('customerId', localStorage.getItem('customerId'));
//     }
    
//     // console.log('data', data, data.getAll('files'));
//     const headers = {
//       // 'Content-Type': 'application/json',
//       'Authorization': 'Bearer ' + token,
//     };
  
//     // axios.post(url + "/bucket/object", data, { 
//     axios.post(url + "/bucket/objects", data, {  
//       headers: headers
//       // receive two    parameter endpoint url ,form data
//     })
//     .then(res => { // then print response status
//       // console.log(res);
//       resolve(res);
//     }).catch(err => {
//       console.log(err);
//       reject(err);
//     });
//   })

//   // axios.post("http://localhost:4000/upload", data, { 
//   //   // receive two    parameter endpoint url ,form data
//   // })
//   // .then(res => { // then print response status
//   //   console.log(res);
//   //   // console.log(res.statusText)
//   // }).catch(err => {
//   //   console.log(err);
//   // });


// };


export const s3fileUpload2 = (
  url, 
  token, 
  path, 
  selectedFiles, 
  bucketName, 
  dispatch,
  uploadApp,
) => {
  return new Promise((resolve, reject) => {
    const data = new FormData(); 

    // console.log(selectedFiles);
    for (const file of selectedFiles) {
      data.append('files', file);
    }
    // data.append('files', selectedFiles);

    data.append('storePath', path);
    data.append('bucket', bucketName);
    
    if (localStorage.getItem('customerId')) {
      data.append('customerId', localStorage.getItem('customerId'));
    }
    
    if (uploadApp) {
      data.append('uploadApp', uploadApp);
    }

    // console.log('data', data, data.getAll('files'));
    const headers = {
      // 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    };
  
    // axios.post(url + "/bucket/object", data, { 
    // axios.post(url + "/bucket/objects", data, {  
    //   headers: headers
    //   // receive two    parameter endpoint url ,form data
    // })
    axios.request({
      method: 'post',
      url: url + "/bucket/objects",
      data: data,
      headers: headers,
      onUploadProgress: (p) => {
        // console.log('onUploadProgress', (p.loaded/p.total*100).toFixed(0), p); 

        let message = `Uploading... ${(p.loaded/p.total*100).toFixed(0)}%`;

        if (p.loaded/p.total === 1) {
          message = 'Uploaded, please wait until storing files finish...'
        }

        dispatch('SHOW_NOTIFICATION', {
          status: '',
          title: message,
          message: '',
        });
      }
    })
    .then(res => { // then print response status
      // console.log(res);
      resolve(res);
    }).catch(err => {
      console.log(err);
      reject(err);
    });
  })

};


// export const createFolderAddedObjectList = (key, topLevels, currentPrefix) => {
//   let addedList;

//   if (!currentPrefix) {
//     const addObject = {
//       Prefix: key,
//       Key: key
//     };

//     addedList = topLevels.concat(addObject);
//   } 
//   else {
//     const addObject = {
//       Key: key
//     };

//     addedList = topLevels.concat(addObject);
//   }

//   return addedList;
// };