import { useState, useEffect, Fragment } from 'react';
// import _, { isLength } from 'lodash';
// import axios from 'axios';
import AboutAuth from '../../UI/AboutAuth';
import Backdrop from '../../Backdrop/Backdrop';
import Modal from '../../Modal/Modal';
import FilesUploadAction from '../../FileUploadAction/FilesUploadAction';

import { useStore } from '../../../hook-store/store';

import './TranscriberFileUpload.css';

function TranscriberFileUpload(props) {

  const { 
    uploadFiles,
    setShowUploadModal,
  } = props;

  const [store, dispatch] = useStore();
  // console.log('store-App.tsx', store);
  const { isAuth } = store.uiStore;
  const { 
      mlSpeechCurrentAudioData,
      mlSpeechText,
  } = store.mlSpeechStore;


  // useEffect(() => {
  //   if (gLoading) {
  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //   }
  // },[gLoading]);

  let displayText = mlSpeechText;

  if (mlSpeechText.length > 200) {
    displayText = mlSpeechText.slice(0, 200) + '....';
  }

  // console.log(uploadFiles)
  let transcriberFileUploadBody;

  transcriberFileUploadBody = (
    <div>
      <Backdrop 
        zIndex={'95'}
        onCancel={() => { 
          setShowUploadModal(false);
        }}
      />
      <Modal
        onClose={() => {
          setShowUploadModal(false);
        }}
      >
        <div>
          <div className='transcriberUploadSection'>
            <div className='transcriberUploadTitle'>
              Upload text to Kura Storage
            </div>
          </div>
          <div className='transcriberUploadSection'>
            {/* <div>text content</div> */}
            <div>
              {displayText} 
            </div>
            {/* {mlSpeechText.length} */}
          </div>
          <br/>

          {isAuth && (
            <div className='transcriberUploadSection'>
              <FilesUploadAction 
                uploadFiles={uploadFiles}
                uploadApp={'audioTranscriber'}
              />
            </div>
          )}
          {!isAuth && (
            <div className='transcriberUploadSection'>
              <AboutAuth />
            </div>
          )}
        </div>
      </Modal>
    </div>
  )


  
  return (
    <Fragment>
      {transcriberFileUploadBody}
    </Fragment>
  );
}

export default TranscriberFileUpload;
