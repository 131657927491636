import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { useStore } from "../../hook-store/store";

import { 
  loginPageLink, 
  signupPageLink, 
  kuraStorageLink
} from "../../App";

import { marks } from "../../utils/marks";

export default function AboutAuth() {

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;

  return (
    <Fragment>
      <div>
        <p>
          Kura Storage 
          {' '}
          {t('aboutAuth01', 'provide simple storage place for your files. You can upload, store, access, and download files.')}
        </p>
        <p>
        {t('aboutAuth02', 'There is free storage space to upload files. It is possibe to access files from both mobile devices and desktop devices.')}
        </p>
    
        <div>
          <a href={`${kuraStorageLink}/about`}
            target="_blank" rel="noopener noreferrer"
          >
            {t('aboutAuth03', 'More detail')} 
            {' '}
            about Kura Storage {marks.externalLinkMark}
          </a>
        </div>
        <br/>

        <p>
        {t('aboutAuth04', 'Login to upload and store files, or create account')}
        </p>
        <p>
          ({t('aboutAuth05', 'Please make sure to download or save file before login.')})
        </p>
        <br/>
        
        <div>
          <a href={loginPageLink}>
            <button className="btnBase">
              {marks.signInMrak} {t('aboutAuth06', 'Login')}
            </button>
          </a>
          <span>, or </span>
          <a href={signupPageLink}>
            <button className="btnBase">
              {marks.userPlusMrak} {t('aboutAuth07', 'Signup')}
            </button>
          </a>
        </div>
      </div>
    </Fragment>
  );
}