import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

import reportWebVitals from './reportWebVitals';

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { SUBSCRIPTION_BACKEND_URL } from "./App";

import './i18n';

import configureUiStore from './hook-store/ui-store';
import configureMlSpeechStore from './hook-store/ml-speech-store';
import configureMlTranslateStore from './hook-store/ml-translate-store';
import configureMlVoiceStore from './hook-store/ml-voice-store.js';
import configureBucketObjectStore from './hook-store/bucket-object-store';
import configureStripeDataStore from "./hook-store/stripe-data-store";
import configureServiceBucketsStore from './hook-store/service-buckets-store';
import configureObjectMetadataStore from './hook-store/object-metadata-store';


configureUiStore();
configureMlSpeechStore();
configureMlTranslateStore();
configureMlVoiceStore();
configureBucketObjectStore();
configureStripeDataStore();
configureServiceBucketsStore();
configureObjectMetadataStore();


const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
root.render(
    <App />
);


//// load stripe publishableKey
fetch(SUBSCRIPTION_BACKEND_URL + '/price/config')
  .then(r => {
    return r.json()
  })
  .then(resData => {
    console.log('stripe-config resData', resData);
    const stripePromise = loadStripe(resData.publishableKey);

    // const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </React.StrictMode>
    );

  })
  .catch(err => {
    // console.log('stripe-config err', err)
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
