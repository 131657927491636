import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_OUTPUTBLOBURL: (state, payload) => {
      return { 
        // ...state,
        mlVoiceStore : {
          ...state.lmVoiceStore,
          outputBlobUrl: payload,
        }
      };
    },
  };

  initStore(actions, {
    mlVoiceStore: {
      outputBlobUrl: null,
      // socketId: '',
      // isBackendApi: false,
      // isSScreen: false,
    }
  });
};

export default configureStore;
