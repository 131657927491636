import { Fragment } from 'react';
// import { useTranslation } from "next-i18next";
// import i18next from 'i18next';

// import Loader from '../Loader/Loader';
import { useStore } from '../../hook-store/store';

// import classes from './Modal.module.css'

function ModalPosition(props) {
  // console.log('modalPosition-props', props);
  const { 
    top, 
    left, 
    right, 
    bottom, 
    heightLimit,
    widthLimit,
    zIndex, 
    position,
    modalStyle, 
    onClose, 
    isLoading 
  } = props;

  const [store, dispatch] = useStore();
  // console.log(store, store.uiStore);
  const windowValues = store.uiStore.windowValues;

  // const { t } = useTranslation("common");

  // function cancelHandler() {
  //   props.onCancel();

  //   if (props.setSelectAction) {
  //     props.setSelectAction('');
  //   }
  // }

  // function confirmHandler() {
  //   if (props.selectAction === 'DELETE') {
  //     console.log('DELETE clicked');
  //     props.onDelete(props.roomData);
  //   }
  //   else {
  //     props.onConfirm(props.roomData);
  //   }    
  // }

  // let buttonName = 'Confirm';
  // let buttonName = 'Make Reservation';
  // let buttonName = i18next.t("roomFormText7")
  // if (props.selectAction === 'DELETE') {
  //   // buttonName = 'Delete'
  //   buttonName = i18next.t("generalText3")
  // }
  // if (props.selectAction === 'EDIT') {
  //   // buttonName = 'Edit'
  //   buttonName = i18next.t("generalText5")
  // }

  let modalPositionStyle;

  // if (window.innerWidth < 480 || window.innerHeight < 480) {
  if (windowValues && 
      (windowValues.height < 480 || windowValues.width < 480)
  ) {
    if (modalStyle) {
      modalPositionStyle = modalStyle;
    } else {
      modalPositionStyle = {
        position: 'fixed',
        // top: top && `${top}px`,
        // left: left && `${left}px`,
        // right: right && `${right}px`,
        // width: '80%',
        maxHeight: '40vh',
        // bottom: '5%',
        top: '50%',
        left: '10%',
        right: '10%',
        margin: '0 auto',
        zIndex: zIndex,
      
        overflow: 'auto',
        boxShadow: '0 1px 4px rgba(0, 0, 0, 0.2)',
        backgroundColor: 'white',
        // padding: '0.5rem',
        border: '0.5px solid gray',
        borderRadius: '4px',
      }
    }
  } else { 
    if (modalStyle) {
      modalPositionStyle = modalStyle;
    } else {
      modalPositionStyle = {
        position: position ? position : 'fixed',
        // position: 'absolute',
        top: top && `${top}px`,
        left: left && `${left}px`,
        right: right && `${right}px`,
        bottom: bottom && `${bottom}px`,
        // right: 5%;
        zIndex: zIndex,
        maxWidth: widthLimit ? `${widthLimit}px` : '200px',
        maxHeight: heightLimit ? `${heightLimit}px` : '300px',
        overflowY: 'auto',
        boxShadow: '0 1px 4px rgba(0, 0, 0, 0.2)',
        backgroundColor: 'white',
        // padding: '0.5rem',
        border: '0.5px solid gray',
        borderRadius: '4px',
      }
    }
  }

  return (
    <Fragment>
      <div style={modalPositionStyle}>

        {/* <div style={{textAlign:'right'}}>
          <strong style={{cursor:'pointer'}} 
          onClick={() => {
            if (!isLoading) {
              onClose();
            } 
          }}
          >
            X
          </strong>
        </div> */}

        <span>{props.children}</span>
        
      </div>
    </Fragment>
  );
}

export default ModalPosition;