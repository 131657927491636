import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_MLTRANSLATEDTEXT: (state, payload) => {
      return { 
        // ...state,
        mlTranslateStore : {
          ...state.mlTranslateStore,
          mlTranslatedText: payload, 
        }
      };
    },

  };

  initStore(actions, {
    mlTranslateStore: {
      mlTranslatedText: null,  
    }
  });
};

export default configureStore;
