import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import i18n from '../../i18n';
import LanguageList from '../../translation/LanguageList';
import Modal from '../../components/Modal/Modal';
import Backdrop from '../../components/Backdrop/Backdrop';

import { useStore } from "../../hook-store/store";

import { getUserData } from "../../utils/user/user";
import { resources } from '../../translation/translation';

import { BACKEND_URL, USERACCOUNTPAGE_URL } from "../../App";

import { marks } from "../../utils/marks";
import classes from './UserInfo.module.css';

const UserInfo = (props) => {
  // const { } = props;
  const selectedLanguage = resources[i18n.language].translation.LANGUAGE;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { 
    isAdmin,
    userNameData,
  } = store.uiStore;

  const [userData, setUserData] = useState();

  const [showLanguageModal, setShowLanguageModal] = useState(false);

  useEffect(() => {
    getUserDataHandler(BACKEND_URL, localStorage.getItem('token'));
  }, []);

  const showLanguageModalHandler = () => {
    setShowLanguageModal(!showLanguageModal);
  };

  const getUserDataHandler = async(url, token) => {
    try {
      const result = await getUserData(url, token);

      console.log(result);

      if (result.data) {
        setUserData(result.data);

        if (result.data.adminStatus === 'admin') {
          dispatch('SET_ISADMIN', true);
        }
      }
    } catch(err) {
      console.log(err);
    }
  };




  const languageModalBody = (
    <div>
      <Backdrop onCancel={showLanguageModalHandler} />
      <Modal
        onClose={showLanguageModalHandler}
      >
        <LanguageList 
          showLanguageModalHandler={showLanguageModalHandler}
          // getLanguageNameHandler={getLanguageNameHandler}
          // i18n={props.i18n}
        />
      </Modal>
    </div>
  );

  let userInfoBody;

  if (userData) {
    userInfoBody = (
      <div className={classes.userInfoContent}>
        
        {/* {isAdmin && (
          <div>
            <div>admin-user</div>
            <div><Link to="/admin">admin-page</Link></div>
          </div>
        )} */}

        {userNameData && (
          <div className={classes.userInfoItem}>
            name
            <strong>{userNameData.name}</strong>
          <a
            href={`${USERACCOUNTPAGE_URL}?tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
            target="_blank" rel="noopener noreferrer"
          >
            <button className="btnBase" style={{fontWeight: "normal"}}
              title={'go to user account page for update'}
            >
              update {marks.externalLinkMark}
            </button>
          </a>
          </div>
        )}
        <div className={classes.userInfoItem}>
          {t('userInfo.01', 'Email address')}
          <strong>{userData.email}</strong>
        </div>
        
        {userNameData && (
          <div className={classes.userInfoItem}>
            user image
            {userNameData.imageUrl && (
              <img className={classes.userInfoUserImage}
                src={userNameData.imageUrl}
              />
            )}
            {!userNameData.imageUrl && (
              <span className={classes.userInfoUserNoImage}>
                {marks.userMrak}
              </span>
            )}
            <a
              href={`${USERACCOUNTPAGE_URL}?tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
              target="_blank" rel="noopener noreferrer"
            >
              <button className="btnBase" style={{fontWeight: "normal"}}
                title={'go to user account page for update'}
              >
                update {marks.externalLinkMark}
              </button>
            </a>
          </div>
        )}
     
        <div className={classes.userInfoItem}>
          {t('userInfo.02', 'Creation time')}
          <strong>{moment(userData.createdAt).format("YYYY-MM-DD")}</strong>
        </div>
        <div className={classes.userInfoItem}>
          <span>
            {t('userInfo.text03', 'Language')}
          </span>
          <span>
            <strong>{selectedLanguage}</strong>
          </span>
          <span>
            <button className="btnBase"
              // disabled={descriptionInput ? descriptionInput.length > 300 : null}
              onClick={showLanguageModalHandler}
            >
              {t('userInfo.text04', 'Change')}
            </button>
          </span>
        </div>
      </div>
    );
  }
 

  return <Fragment>
    <div className="pageContentContainer">

      {userInfoBody}

      {showLanguageModal && (
        <div>{languageModalBody}</div>
      )}
    </div>
  </Fragment>;
};

export default UserInfo;
